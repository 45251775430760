var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import styled from '@emotion/styled';
import { ContainerColumn, NarrowSection } from '@src/components/styled';
import { useGetCurrentProfilePortfoliosQuery } from '@shared/api/portfolio';
import ConnectExchangeEmptyView from '@src/pages/Overview/components/ConnectExchangeEmptyView';
import { useAppSelector } from '@app/store/Hooks';
import { useGetExchangeAccountsQuery, useGetSupportedWalletTypesQuery, } from '@shared/api/exchange';
import { IsDefined } from '@utils/js-ts';
import { mapSupportedWalletsDict } from '@entities/wallet';
import { getWalletsFromExchanges } from '@entities/wallet/lib/utils';
import { mapPortfoliosListFromResponse } from '@entities/portfolio';
import OverallBalance from './OverallBalance';
import ClientOverview from './ClientOverview';
import TraderOverview from './TraderOverview';
const Container = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    gap: '36px',
}));
const NarrowContainer = styled(NarrowSection.withComponent(ContainerColumn))((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
const HeaderContainer = styled(ContainerColumn)((props) => ({
    height: 'auto',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: `${props.theme.customColors.surface.surface}`,
    borderBottom: `1px solid ${props.theme.customColors.surface.outline}`,
    paddingTop: props.theme.spacing_sizes.l,
    paddingBottom: '36px',
    gap: '48px',
}));
const Overview = () => {
    const { currentUserProfile } = useAppSelector(store => store.profile);
    const { user } = useAppSelector(store => store.user);
    const { status: exchangesStatus, data: { exchangesList, wallets }, } = useGetExchangeAccountsQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: {
                    exchangesList: (data || []),
                    wallets: getWalletsFromExchanges(data || []),
                } }));
        },
    });
    const { data: { data: supportedWallets }, } = useGetSupportedWalletTypesQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: mapSupportedWalletsDict(data) }));
        },
    });
    const { currentData: portfoliosList, } = useGetCurrentProfilePortfoliosQuery({ profileId: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id }, {
        selectFromResult: (_a) => {
            var { currentData } = _a, rest = __rest(_a, ["currentData"]);
            return (Object.assign(Object.assign({}, rest), { currentData: mapPortfoliosListFromResponse(currentData || []) }));
        },
    });
    const hasWallets = exchangesList && exchangesList.length > 0;
    return (_jsxs(Container, { children: [_jsx(HeaderContainer, { children: _jsx(NarrowContainer, { children: _jsx(OverallBalance, { wallets: wallets, portfolios: portfoliosList || [] }) }) }), _jsxs(NarrowContainer, { children: [(exchangesStatus !== QueryStatus.pending) &&
                        !hasWallets && (_jsx(ConnectExchangeEmptyView, {})), hasWallets && (IsDefined(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) &&
                        (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT') && (_jsx(ClientOverview, { wallets: wallets || [], exchanges: exchangesList || [], 
                        // TODO-FIX: at current moment we arent able to fetch
                        // portfolios which client is subscribed to
                        // portfolios={portfoliosList.list || []}
                        supportedWalletsDict: supportedWallets })), hasWallets && (IsDefined(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) &&
                        (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER') && (_jsx(TraderOverview, { wallets: wallets || [], exchanges: exchangesList || [], portfolios: portfoliosList || [], canSwitchProfile: Boolean(user === null || user === void 0 ? void 0 : user.canSwitchProfile), profileType: currentUserProfile.type, supportedWalletsDict: supportedWallets }))] })] }));
};
export default Overview;
